import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import SEO from "../components/seo"

const Post = ({ data }) => {
  const post = data.ghostPost
  return (
    <Layout>
      <SEO />

      <div
        className="pb-3 no-home-header"
        style={{
          height: 140,
          backgroundSize: "cover",
        }}
      />

      <div className="ec-blog single-post">
        <div className="container">
          <div className="col-md-9 mx-auto text-center my-5">
            <div className="post-meta text-uppercase my-3 d-flex justify-content-center">
              {post.published_at}
              <ul className="post-categories">
                <li>
                  <a rel="category tag">{post.tags[0] && post.tags[0].name}</a>
                </li>
              </ul>
            </div>
            <h1 className="single-post-title text-capitalize text-black">
              {post.title}
            </h1>
          </div>
        </div>

        <div className="single-post-img text-center">
          <img
            className="w-100"
            src={post.feature_image}
            data-pagespeed-url-hash="3048369740"
          />
        </div>
        <div className="post-content container pt-3">
          <div className="inner py-3">
            <div
              className="content-block p-5 m-auto"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Post

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      id
      title
      slug
      html
      feature_image
      tags {
        name
      }
      authors {
        profile_image
      }
      published_at(formatString: "MMMM DD, YY")
    }
  }
`
